<template>
	<div>
    <v-container>
      <v-row align="center" dense>
        <v-btn class='text-button pl-0' color="indigo darken-2" plain @click="back" height="10">構造物一覧</v-btn>
        <v-icon class="arrow-icon">mdi-chevron-right</v-icon>
        <h3 class='text-h5 grey--text text--darken-2'>構造物詳細</h3> 
      </v-row>
      <br>

      <v-row>
        <v-col><v-spacer></v-spacer></v-col>
      </v-row>
      <v-row>
        <v-col><v-spacer></v-spacer></v-col>
      </v-row>

      <v-row dense justify="start" align="center">
        <!-- <v-col cols="2" sm="2" md="2" lg="1" xl="1"> -->
        <v-btn class='text-button ml-3' dark color="indigo darken-1" @click="runPrint">
        <v-icon>mdi-printer</v-icon>
        <pre> </pre>
          印刷
        </v-btn>
        <!-- </v-col> -->
        
        <v-divider v-if="this.$store.state.account['localAccountId']==this.$store.state.currentData['userId']"
        vertical class='pl-6'></v-divider>

        <v-dialog v-if="this.$store.state.account['localAccountId']==this.$store.state.currentData['userId']"
          v-model="showDeleteDiaglog"
          persistent
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              v-bind="attrs"
              class='text-button'
              plain
              v-on="on">
            <!-- <v-icon color="indigo darken-2">mdi-trash-can</v-icon> -->
            <v-icon color="red darken-2">mdi-trash-can</v-icon>
              削除
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              データ削除
            </v-card-title>
            <v-card-text class="font-weight-bold">このデータを削除しますか？</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="showDeleteDiaglog = false"
                depressed
              >
                キャンセル
              </v-btn>
              <v-btn
                color="error"
                depressed
                @click="runDelete"
                class="font-weight-bold"
              >
                削除
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>

      <v-row dense>
        <v-col><v-spacer></v-spacer></v-col>
      </v-row>

      <v-divider></v-divider>

      <br>
      <!-- <v-row>
        <v-col md=2>
          <v-btn class='text-button' color="indigo darken-2" plain @click="back">構造物一覧 ></v-btn>
        </v-col>
      </v-row>

      <br>
      <v-row dense>
        <v-col>
          <p class='text-h5 grey--text text--darken-2'>構造物詳細</p>
        </v-col>
        <v-col align="end">
          <v-btn class='text-button' dark color="indigo darken-1" @click="runPrint">
            印刷
          </v-btn>
        </v-col>       
      </v-row>
      <v-divider></v-divider>
      <br> -->


      <div id="printed-area">
        <!-- 1ページ目 -->
        <v-sheet max-width="1000">
          <v-container>
            <v-row no-gutters>
              <pre>   </pre>
              <v-col align="start" class="text-h6 grey--text text--darken-2 font-weight-bold title">
                AI診断士  構造物診断票
                <pre>     </pre>
              </v-col>
              <v-col align="end" class="vals">
                1/3ページ<v-icon>mdi-chevron-right</v-icon>
              </v-col>
            </v-row>
            <!-- 施設情報 -->
            <v-row dense>
              <pre>      </pre>
              <p class='text-subtitle-1 grey--text text--darken-2 text-decoration-underline font-weight-bold margin2'>施設情報</p>
            </v-row>
            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>構造物種類：</p>
              <p align="start" class="content11 " outlined tile >{{this.$store.state.currentData['kouzoubutsusyurui']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>所在地：</p>
              <p align="start" class="content3" outlined tile >{{this.$store.state.currentData['syozaichi']}}</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>構造物名：</p>
              <p align="start" class="content11 " outlined tile >{{this.$store.state.currentData['kouzoubutsumei']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>管理者名：</p>
              <p align="start" class="content3" outlined tile >{{this.$store.state.currentData['kanrisyamei']}}</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>路線名：</p>
              <p align="start" class="content11 " outlined tile >{{this.$store.state.currentData['rosenmei']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined></p>
              <p align="start" class="content3" outlined tile ></p>
            </v-row>

            <!--  入力 -->
            <v-row dense>
              <pre>      </pre>
              <p class='text-subtitle-1 grey--text text--darken-2 text-decoration-underline font-weight-bold margin2'>入力</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>緯度経度：</p>
              <p align="start" class="content11 " outlined tile >{{this.$store.state.currentData['ido']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined></p>
              <p align="start" class="content3" outlined tile ></p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined></p>
              <p align="start" class="content11 " outlined tile >{{this.$store.state.currentData['keido']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined></p>
              <p align="start" class="content3" outlined tile ></p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>離岸距離[m]：</p>
              <p align="start" class="content11 " outlined tile >{{this.$store.state.currentData['rigankyori']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>ASR地質範囲内：</p>
              <p align="start" class="content3" outlined tile >{{this.$store.state.currentData['asr']}}</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>年降水量[0.1mm]：</p>
              <p align="start" class="content11 " outlined tile >{{this.$store.state.currentData['nenkousuiryou']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>0°C以下日数：</p>
              <p align="start" class="content3" outlined tile >{{this.$store.state.currentData['zerodoika']}}</p>
            </v-row>

            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>架設年次[西暦]：</p>
              <p align="start" class="content12 " outlined tile >{{this.$store.state.currentData['kasetsunenzi']}}</p>
              
              <p align="end" class="item2 text-body-1 grey--text text--darken-2" outlined>橋長[m]：</p>
              <p align="start" class="content22" outlined tile >{{this.$store.state.currentData['kyouchou']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>損傷種類</p>
              <p align="start" class="content3" outlined tile ></p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>交通量[台数]：</p>
              <p align="start" class="content12 " outlined tile >{{this.$store.state.currentData['koutsuuryou']}}</p>
              
              <p align="end" class="item2 text-body-1 grey--text text--darken-2" outlined>幅員[m]：</p>
              <p align="start" class="content2" outlined tile >{{this.$store.state.currentData['hukuin']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>主損傷：</p>
              <p align="start" class="content3" outlined tile >{{this.$store.state.currentData['sonsyousyurui']}}</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>大型車混入率[%]：</p>
              <p align="start" class="content12 " outlined tile >{{this.$store.state.currentData['oogatasyakonnyuuritsu']}}</p>
              
              <p align="end" class="item2 text-body-1 grey--text text--darken-2" outlined>桁本数：</p>
              <p align="start" class="content2" outlined tile >{{this.$store.state.currentData['ketahonsuu']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>他損傷1：</p>
              <p align="start" class="content3" outlined tile >{{this.$store.state.currentData['sonsyousyuruiz1']}}</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>床版厚[mm]：</p>
              <p align="start" class="content12 " outlined tile >{{this.$store.state.currentData['syoubanatsu']}}</p>
              
              <p align="end" class="item2 text-body-1 grey--text text--darken-2" outlined>対象部材：</p>
              <p align="start" class="content2" outlined tile >{{this.$store.state.currentData['hosyuutaisyoubuzai']}}</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>他損傷2：</p>
              <p align="start" class="content3" outlined tile >{{this.$store.state.currentData['sonsyousyuruiz2']}}</p>
            </v-row>

            <!-- <v-row no-gutters>
              <v-card align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>年降水量[0.1mm]：</v-card>
              
              <v-card align="start" class="content12 " outlined tile >999999</v-card>
              
              <v-card align="end" class="item2 text-body-1 grey--text text--darken-2" outlined>対象部材：</v-card>
              <v-card align="start" class="content2" outlined tile >沓座・台座コンクリート</v-card>

              <v-card align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>ASR地質範囲内：</v-card>
              <v-card align="start" class="content3" outlined tile >補修・補強材の損傷</v-card>

            </v-row> -->
<!-- 
            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>年降水量[0.1mm]：</p>
              
              <p align="start" class="content12 " outlined tile >999999</p>
              
              <p align="end" class="item2 text-body-1 grey--text text--darken-2" outlined>対象部材：</p>
              <p align="start" class="content2" outlined tile >沓座・台座コンクリート</p>

              <p align="end" class="item3 text-body-1 grey--text text--darken-2" outlined>ASR地質範囲内：</p>
              <p align="start" class="content3" outlined tile >補修・補強材の損傷</p>

            </v-row> -->
            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>
            <v-row dense>
              <pre>      </pre>
              <p class='text-subtitle-1 grey--text text--darken-2 text-decoration-underline font-weight-bold margin2'>状況写真</p>
            </v-row>
            <v-row no-gutters>
              <pre>        </pre>
              <!-- <canvas id='canvas' class="canvas"></canvas>
              <img v-if="img_draw != ''" :src="img_draw" class="images"> -->
              <img v-if="img != ''" :src="img" class="img">
              <pre>     </pre>
              <v-col no-gutters>
                <p class="image-text">撮影日時：<br>{{this.$store.state.currentData['imagedate']}}</p>
                <pre>        </pre>
                <div class="image-text">写真からの損傷原因推定結果：<br><p align="right" class="damageReasonPhoto">{{this.$store.state.currentData['sonsyougenin']}}</p></div>
                <!-- <v-row no-gutters align="end" >
                  <p class="image-text">写真からの損傷原因推定結果：</p>
                </v-row>
                <v-row no-gutters align="start">
                  {{this.$store.state.currentData['sonsyougenin']}}
                </v-row> -->
                <pre>        </pre>
                <p class="image-text">※ 赤枠はユーザーによる選択範囲</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>
            <v-row no-gutters>
              <pre>                    </pre>
              &lt;損傷原因({{this.$store.state.currentData['sonsyougenin']}})の類似写真&gt;
            </v-row>
            <v-row dense v-if="this.$store.state.currentData['sonsyougenin'] != 'その他'">
              <img v-if="similar_1 != ''" :src="similar_1" class="similar1">
              <img v-if="similar_2 != ''" :src="similar_2" class="similar2">
            </v-row>
            <v-row v-else>
              <pre>        </pre>
              <p class='text-body-1 grey--text text--darken-2 font-italic print-italic'>「その他」の類似写真はありません</p>
            </v-row>
            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>

          </v-container>
        </v-sheet>
        <br class="print-none">

        <div class="pagebreak"></div>
        <!-- 2ページ目 -->
        <v-sheet max-width="1000">
          <v-container>
            <v-row no-gutters>
              <v-col align="end" class="vals">
                2/3ページ<v-icon>mdi-chevron-left</v-icon><v-icon>mdi-chevron-right</v-icon></v-col>
            </v-row>

            <!--  推定結果 -->
            <!-- <v-row>
              <v-col align="center">
                <hr width="96%" size="1" noshade >
              </v-col>
            </v-row> -->

            <v-row dense>
              <pre>      </pre>
              <p class='text-subtitle-1 grey--text text--darken-2 text-decoration-underline font-weight-bold margin2'>推定結果</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item12 text-body-1 grey--text text--darken-2" outlined>損傷原因</p>
              <p align="start" class="content11 " outlined tile ></p>

              <p align="end" class="item32 text-body-1 grey--text text--darken-2" outlined></p>
              <p align="start" class="content3" outlined tile ></p>
            </v-row>
            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>主原因：</p>
              <p align="start" class="content13 " outlined tile >{{this.$store.state.currentData['sonsyougenin']}}</p>

              <p align="end" class="item32 text-body-1 grey--text text--darken-2" outlined>補修工法種別候補1：</p>
              <p align="start" class="content32" outlined tile >{{this.$store.state.currentData['taisakukouhousyubetsu']}}</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>他原因1：</p>
              <p align="start" class="content13 " outlined tile >{{this.$store.state.currentData['sonsyougeninz1']}}</p>

              <p align="end" class="item32 text-body-1 grey--text text--darken-2" outlined>補修工法種別候補2：</p>
              <p align="start" class="content32" outlined tile >{{this.$store.state.currentData['taisakukouhousyubetsuz1']}}</p>
            </v-row>

            <v-row no-gutters>
              <p align="end" class="item1 text-body-1 grey--text text--darken-2" outlined>他原因2：</p>
              <p align="start" class="content13 " outlined tile >{{this.$store.state.currentData['sonsyougeninz2']}}</p>

              <p align="end" class="item32 text-body-1 grey--text text--darken-2" outlined>補修工法種別候補3：</p>
              <p align="start" class="content32" outlined tile >{{this.$store.state.currentData['taisakukouhousyubetsuz2']}}</p>
            </v-row>

            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>

            <v-row no-gutters>
              <div align="start" class="ippan-text-1" outlined>{{this.$store.state.currentData['taisakukouhousyubetsu']}}：</div>
              <div align="start" class="ippan-text-2" outlined>{{this.$store.state.currentData['ippanhosyuukouhou']}}</div>
            </v-row>
            <v-row align="center" class="align-center" no-gutters>
              <p align="end" class="ippan-text-1" outlined></p>
              <ul class="sb-products pruduct-list">
                <li class="sb-product-line" v-for="item in this.sbItems1" :key="item" >
                {{item}}
                </li>
              </ul>
              <hr width="1%" size="1" noshade>
              <span class="vals">ショーボンド工法</span>
            </v-row>
            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>

            <v-row no-gutters>
              <div align="start" class="ippan-text-1" outlined>{{this.$store.state.currentData['taisakukouhousyubetsuz1']}}：</div>
              <div align="start" class="ippan-text-2" outlined>{{this.$store.state.currentData['ippanhosyuukouhouz1']}}</div>
            </v-row>
            <v-row align="center" class="align-center" no-gutters>
              <p align="end" class="ippan-text-1" outlined></p>
              <ul class="sb-products pruduct-list">
                <li class="sb-product-line" v-for="item in this.sbItems2" :key="item" >
                {{item}}
                </li>
              </ul>
              <hr width="1%" size="1" noshade>
              <span class="vals">ショーボンド工法</span>
            </v-row>
            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>

            <v-row no-gutters>
              <div align="start" class="ippan-text-1" outlined>{{this.$store.state.currentData['taisakukouhousyubetsuz2']}}：</div>
              <div align="start" class="ippan-text-2" outlined>{{this.$store.state.currentData['ippanhosyuukouhouz2']}}</div>
            </v-row>
            <v-row align="center" class="align-center" no-gutters>
              <p align="end" class="ippan-text-1" outlined></p>
              <ul class="sb-products pruduct-list">
                <li class="sb-product-line" v-for="item in this.sbItems3" :key="item" >
                {{item}}
                </li>
              </ul>
              <hr width="1%" size="1" noshade>
              <span class="vals">ショーボンド工法</span>
            </v-row>
            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>

          </v-container>
        </v-sheet>
        <br>

        <div class="pagebreak"></div>
        <!-- 3ページ目 -->
        <v-sheet max-width="1000">
          <v-container>
            <v-row no-gutters>
              <v-col align="end" class="vals">
                3/3ページ<v-icon>mdi-chevron-left</v-icon></v-col>
            </v-row>

            <!--  SHAP(損傷原因) -->
            <v-row dense>
              <pre>      </pre>
              <p class='text-subtitle-1 grey--text text--darken-2 text-decoration-underline font-weight-bold margin2'>
                SHAP（主原因：{{this.$store.state.currentData['sonsyougenin']}}・・・{{this.$store.state.currentData['sonsyouorigin']}}を採用）
              </p>
            </v-row>
            <v-row no-gutters>
              <pre>      </pre>
              <!-- <p class="body-1 text--darken-2">（{{this.$store.state.currentData['sonsyouorigin']}}）</p> -->
              <p class="body-1 text--darken-2">※SHAP値は{{this.$store.state.currentData['sonsyougenin']}}のデータAIに基づく</p>
            </v-row>
            <v-row no-gutters align="center">
              <v-col>
                <pre>      </pre>
                <img v-if="shap_damage != ''" :src="shap_damage" class="shap">
              </v-col>
              <v-col>
                <v-card
                  class="mx-auto shap-text"
                  outlined
                >
                <v-card-subtitle class="shap-comment">SHAPの解説：</v-card-subtitle>
                <v-card-text class="shap-comment"> {{ shap_damage_text }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col><v-spacer></v-spacer></v-col>
            </v-row>
            <v-row dense>
              <pre>      </pre>
              <p class='text-subtitle-1 grey--text text--darken-2 text-decoration-underline font-weight-bold margin2'>
                SHAP（補修工法種別候補1：{{this.$store.state.currentData['taisakukouhousyubetsu']}}・・・{{this.$store.state.currentData['taisakuorigin']}}を採用）
              </p>
            </v-row>
            <v-row no-gutters>
              <pre>      </pre>
              <p class="body-1 text--darken-2">※SHAP値は{{this.$store.state.currentData['taisakukouhousyubetsu']}}のデータAIに基づく</p>
            </v-row>
            <v-row no-gutters align="center">
              <v-col>
                <pre>      </pre>
                <img v-if="shap_type != ''" :src="shap_type" class="shap">
              </v-col>
              <v-col>
                <v-card
                  class="mx-auto shap-text"
                  outlined
                >
                <v-card-subtitle class="shap-comment">SHAPの解説：</v-card-subtitle>
                <v-card-text class="shap-comment"> {{ shap_type_text }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
        <!-- <br> -->
        <div class="pagebreak"></div>

        <!-- 4ページ目 -->
        <!-- <v-sheet max-width="1000">
          <v-container>
            <v-row no-gutters>
              <v-col align="end">
                4/4ページ<v-icon>mdi-chevron-left</v-icon></v-col>
            </v-row>
            <v-row dense>
              <pre>      </pre>
              <p class='text-subtitle-1 grey--text text--darken-2 text-decoration-underline font-weight-bold margin1'>SHAP（一般補修工法名：{{this.$store.state.currentData['ippanhosyuukouhou']}}）</p>
            </v-row>
            <v-row no-gutters align="center">
              <v-col>
                <pre>      </pre>
                <img v-if="shap_name != ''" :src="shap_name" class="shap">
              </v-col>
              <v-col>
                <v-card
                  class="mx-auto shap-text"
                  outlined
                >
                <v-card-subtitle class="shap-comment">SHAPの解説：</v-card-subtitle>
                <v-card-text class="shap-comment"> {{ shap_name_text }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet> -->
      </div>

    </v-container>
	</div>
</template>

<script>
import sb from '../assets/typeNameSB.json'
export default {
  //props: {datas: Object},
  data: () => ({
    img: '',
    img_draw: '',
    shap_damage : '',
    shap_type : '',
    shap_name : '',
    shap_damage_text : '',
    shap_type_text : '',
    shap_name_text : '',
    similar_1 : '',
    similar_2 : '',
    sb : sb,
    sbItems1 : [],
    sbItems2 : [],
    sbItems3 : [],
    showDeleteDiaglog : false,
  }),
  methods: {
    back () {
      this.$router.push('/buildinglist')
    },
    async runDelete() {
      this.showDeleteDiaglog = false
      await this.getAccessToken(this.$store.state.account)
      const data = { 
        ids : this.$store.state.currentData['id']
      };
      await this.$axios.post('/delete', data, {
        headers: {
          Authorization: "Bearer " + this.$store.state.accessToken
        }
        })
        .then ( () => {
          this.$store.dispatch("setSnackbar", "削除しました");
          this.$store.state.gotListState = 0;
          this.back()
        })
        .catch ( err => {
          console.log(err);
        })
    },
    runPrint() {
      // var ctx = document.getElementById('canvas').getContext('2d');
      // var area = document.getElementsByClassName("printed-area")[0]
      // var htmlDoc = document.body.innerHTML;
      // document.body.innerHTML = area.innerHTML;
      window.document.body.innerHTML = document.getElementById("printed-area").innerHTML
      window.print();
      
      let data = JSON.stringify({
        im: this.img,
        shap1: this.shap_damage,
        shap2: this.shap_type,
        currentDataList: JSON.stringify(this.$store.state.currentData),
        comment1: this.shap_damage_text,
        comment2: this.shap_type_text
      })
      // TODO: Webkitかどうか判定できるようにしなければ
      try {
        window.webkit.messageHandlers.print.postMessage(data)
      } catch {
        console.log("not webkit")
      }
      
      this.$router.go({ path: this.$router.currentRoute.path, force: true})
      // this.$router.push({name: 'buildingdetail', params: {datas : this.datas}, force: true})
      // document.body.innerHTML = htmlDoc;
    },
    async getImage(blob, type) {
      await this.getAccessToken(this.$store.state.account)
      // const data = { 
      //   data : name,
      //   type : type
      // }
      const data = { 
        blob : blob
      }
      await this.$axios.post('/image_url', data, {
        headers: {
          Authorization: "Bearer " + this.$store.state.accessToken
        }
        })
        .then ( response => {
          const sasUrl = response.data['message']
          if (type == 'image') {
            this.img = sasUrl
          } else if (type == '損傷原因') {
            this.shap_damage = sasUrl
          } else if (type == '補修工法種別候補') {
            this.shap_type = sasUrl
          } else if (type == '一般補修工法名') {
            this.shap_name = sasUrl
          }
      })
    },
    async getSimilar(damage) {
      await this.getAccessToken(this.$store.state.account)
      const data = { 
        data : damage
      }
      await this.$axios.post('/similar_image_url', data, {
        headers: {
          Authorization: "Bearer " + this.$store.state.accessToken
        }
        })
        .then ( response => {
          this.similar_1 = response.data['url1'];
          this.similar_2 = response.data['url2'];
      })
    },

    // async getImagePoints(type, name) {
    //   await this.getAccessToken(this.$store.state.account)
    //   const data = { 
    //     data : name,
    //     type : type
    //   }
    //   await this.$axios.post('/image_url_points', data, {
    //     headers: {
    //       Authorization: "Bearer " + this.$store.state.accessToken
    //     }
    //     })
    //     .then ( response => {
    //       const sasUrl = response.data['url']
    //       const points = response.data['points']
    //       if (type == 'images') {
    //         this.setImage(sasUrl, points)
    //       }
    //   })
    // },

    async getImagePoints(name) {
      await this.getAccessToken(this.$store.state.account)
      const data = { 
        data : name
      }
      await this.$axios.post('/image_url_points', data, {
        headers: {
          Authorization: "Bearer " + this.$store.state.accessToken
        }
        })
        .then ( response => {
          const sasUrl = response.data['url']
          this.img = sasUrl
          // console.log(this.img)
          // const points = response.data['points']
          // this.setImage(sasUrl, points)
      })
    },

    async setImage(sasUrl, points) {
      await this.drawImage(sasUrl, points)
      var canvas2 = document.getElementById('canvas')
      this.img_draw = canvas2.toDataURL("image/jpeg")
    },

    async drawImage(sasUrl, points) {
      var canvas = document.getElementById('canvas')
      // var ctx = canvas.getContext('2d');
      return new Promise(function(resolve) {
        var im = new Image()
        im.onload = function() {
          let maxsize = 500
          var width = im.naturalWidth;
          var height = im.naturalHeight;
          if (width > height) {
            // ctx.drawImage(im,0,0, 500, (500/width)*height);
            canvas.width = maxsize
            canvas.height = (maxsize/width)*height
          } else {
            // ctx.drawImage(im,0,0, (500/height)*width, 500);
            canvas.width = (maxsize/height)*width
            canvas.height = maxsize
          }

          var ctx = canvas.getContext('2d');
          ctx.drawImage(im,0,0, canvas.width, canvas.height);

          const f = (width > height) ? (maxsize/width) : (maxsize/height)

          ctx.beginPath(); // パスのリセット
          ctx.moveTo(points["points"][0][0]*f, points["points"][0][1]*f); // 開始座標
          for (const i of points["points"].slice(1)){
            ctx.lineTo(i[0]*f, i[1]*f);
          }
          ctx.strokeStyle = "#FF0000";
          ctx.stroke();
          resolve();
        };
        im.crossOrigin = "Anonymous";
        im.src = sasUrl;
      });
    },

    async getComments(type, value) {
      await this.getAccessToken(this.$store.state.account)
      const data = { 
        type : type,
        value : value
      }
      await this.$axios.post('/shap_comment', data, {
        headers: {
          Authorization: "Bearer " + this.$store.state.accessToken
        }
        })
        .then ( response => {
          const comment = response.data['message']
          if (type == '損傷原因') {
            if (this.$store.state.currentData['sonsyouorigin'] == '任意の選択結果' && this.$store.state.currentData['sonsyougenin'] == 'その他') {
              this.shap_damage_text = "ユーザーによる任意選択"
            } else {
              this.shap_damage_text = comment
            }
          } else if (type == '補修工法種別候補') {
            if (this.$store.state.currentData['taisakuorigin'] == '任意の選択結果' && this.$store.state.currentData['taisakukouhousyubetsu'] == 'その他') {
              this.shap_type_text = "ユーザーによる任意選択"
            } else {
              this.shap_type_text = comment
            }
          } else if (type == '一般補修工法名') {
            if (this.$store.state.currentData['ippanorigin'] == '任意の選択結果' && this.$store.state.currentData['ippanhosyuukouhou'] == 'その他') {
              this.shap_name_text = "ユーザーによる任意選択"
            } else {
              this.shap_name_text = comment
            }
          }
      })
    }

  },

  created : function() {
    if (this.$store.state.currentData['imagename'] != null ) {
      this.getImagePoints(this.$store.state.currentData['imagename']);
    }

    if (this.$store.state.currentData['sonsyougenin'] != null ) {
        let filename = this.$store.state.currentData['imagename'].split(".")[0] + '_damageReason.png' 
        this.getImage(`shap/images/damageReason/${filename}`, '損傷原因');
      
      this.getComments("損傷原因", this.$store.state.currentData['sonsyougenin']);
      if (this.$store.state.currentData['sonsyougenin'] != 'その他') {
        this.getSimilar(this.$store.state.currentData['sonsyougenin']);
      }
    }

    if (this.$store.state.currentData['taisakukouhousyubetsu'] != null ) {
        let filename = this.$store.state.currentData['imagename'].split(".")[0] + '_repairType.png' 
        this.getImage(`shap/images/repairType/${filename}`, '補修工法種別候補');

      this.getComments("補修工法種別候補", this.$store.state.currentData['taisakukouhousyubetsu']);

      sb[this.$store.state.currentData['taisakukouhousyubetsu']]['sb'].forEach((item, index) => {
        this.sbItems1.push(`${item}（${sb[this.$store.state.currentData['taisakukouhousyubetsu']]['detail'][index]}）`)
      });
      sb[this.$store.state.currentData['taisakukouhousyubetsuz1']]['sb'].forEach((item, index) => {
        this.sbItems2.push(`${item}（${sb[this.$store.state.currentData['taisakukouhousyubetsuz1']]['detail'][index]}）`)
      });
      sb[this.$store.state.currentData['taisakukouhousyubetsuz2']]['sb'].forEach((item, index) => {
        this.sbItems3.push(`${item}（${sb[this.$store.state.currentData['taisakukouhousyubetsuz2']]['detail'][index]}）`)
      });
    }

    // if (this.$store.state.currentData['ippanhosyuukouhou'] != null ) {
    //   if (this.$store.state.currentData['ippanorigin'] == 'データAIの推定結果' && this.$store.state.currentData['imagename'] != null ) {
    //     let filename = this.$store.state.currentData['imagename'].split(".")[0] + '_repairName.png' 
    //     console.log(filename)
    //     this.getImage(`shap/images/repairName/${filename}`, '一般補修工法名');
    //   } else {
    //     let filename = this.$store.state.currentData['ippanhosyuukouhou'] + '.png' 
    //     this.getImage(`shap/一般補修工法名/${filename}`, '一般補修工法名');
    //   }
    //   this.getComments("一般補修工法名", this.$store.state.currentData['ippanhosyuukouhou']);
    // }
    // this.sbItems1 = sb[this.$store.state.currentData['taisakukouhousyubetsu']]['detail']
    // sb[this.$store.state.currentData['taisakukouhousyubetsu']]['sb'].forEach((item, index) => {
    //   this.sbItems1.push(`${item}（${sb[this.$store.state.currentData['taisakukouhousyubetsu']]['detail'][index]}）`)
    // });
    // sb[this.$store.state.currentData['taisakukouhousyubetsuz1']]['sb'].forEach((item, index) => {
    //   this.sbItems2.push(`${item}（${sb[this.$store.state.currentData['taisakukouhousyubetsuz1']]['detail'][index]}）`)
    // });
    // sb[this.$store.state.currentData['taisakukouhousyubetsuz2']]['sb'].forEach((item, index) => {
    //   this.sbItems3.push(`${item}（${sb[this.$store.state.currentData['taisakukouhousyubetsuz2']]['detail'][index]}）`)
    // });
  }
}

</script>

<style lang="scss" scoped>
  @page {
    //size: auto;
    size: A4;
    // margin: 10mm;
    margin: 0;
  }
  @media print {
    .align-center {
      align-items: center;
    }

    .print-none {
      display: none;
    }

    .vals {
      font-size: 80%;
    };
    .valname{
      font-size: 80%;
    }
    .pagebreak {
      break-after: page;
    };
    .img {
      margin-left: 100px;
      display: block;
      max-width: 400px;
      max-height: 400px;
      width: auto;
      height: auto;
    }
    .similar1 {
      display: block;
      max-width: 300px;
      max-height: 220px;
      width: auto;
      height: auto;
      margin-left:100px;
    }
    .similar2 {
      display: block;
      max-width: 300px;
      max-height: 220px;
      width: auto;
      height: auto;
      margin-left:10px;
    }
    .shap {
      display: block;
      max-width: 500px;
      max-height: 400px;
      width: auto;
      height: auto;
    }
    .margin2 {
      font-weight: bold;
      text-decoration: underline;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .margin3 {
      font-weight: bold;
      text-decoration: underline;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .shap-text {
      margin-top: 40px;
      max-width: 250px;
    }
    .pruduct-list {
      margin-left: 30px;
      // max-width: 500px;
      // color: black;
      font-size: 80%;
    }
    .print-italic {
      font-style: italic;
    }
    .item1 {
      width: 22%;
      font-size: 80%;
    }
    .content11 {
      width: 38%;
      font-size: 80%;
    }
    .content12 {
      width: 8%;
      font-size: 80%;
    }
    .item2 {
      width: 10%;
      font-size: 80%;
    }
    .content2 {
      width: 20%;
      font-size: 80%;
    }
    .content22 {
      width: 17%;
      font-size: 80%;
    }
    .item3 {
      width: 15%;
      font-size: 80%;
    }
    .content3 {
      width: 21%;
      font-size: 80%;
    }
    .item12 {
      width: 20%;
      font-size: 80%;
    }
    .content13 {
      width: 30%;
      font-size: 80%;
    }
    .item32 {
      width: 20%;
      font-size: 80%;
    }
    .content32 {
      width: 21%;
      font-size: 80%;
    }
    .image-text {
      font-size: 80%;
    }

    .ippan-text-1 {
      width: 25%;
      font-size: 80%;
      padding-left: 50px;
    }
    .ippan-text-2 {
      width: 75%;
      font-size: 80%;
    }
    .ippan-text-3 {
      width: 45%;
      font-size: 80%;
    }

    .sb-products {
      position: relative;
      padding: 5px;
    }

    .sb-products::after {
      content: '';
      width: 15px;
      height: 100%;
      position: absolute;
    }

    .sb-products::after {
      border-right: solid 1px #797878;
      border-top: solid 1px #797878;
      border-bottom: solid 1px #797878;
      top: 0;
      right: 0;
    }

    .damageReasonPhoto {
      padding-left: 90px;
    }
    .sb-product-line {
      padding: 3px;
    }

  }
  .title {
    font-weight: bold;
  }

  // body {
  //     -webkit-print-color-adjust: exact;
  // }

  @media screen {
    .img {
      margin-left: 100px;
      display: block;
      max-width: 400px;
      max-height: 400px;
      width: auto;
      height: auto;
    }
    .similar1 {
      display: block;
      max-width: 300px;
      max-height: 250px;
      width: auto;
      height: auto;
      margin-left:100px;
    }
    .similar2 {
      display: block;
      max-width: 300px;
      max-height: 250px;
      width: auto;
      height: auto;
      margin-left:10px;
    }
    .shap {
      display: block;
      max-width: 600px;
      max-height: 450px;
      width: auto;
      height: auto;
    }
    .shap-text {
      margin-bottom: 100px;
      max-width: 300px;
    }
    .item1 {
      width: 22%;
    }
    .content11 {
      width: 38%;
    }
    .content12 {
      width: 8%;
    }
    .item2 {
      width: 10%;
    }
    .content2 {
      width: 20%;
    }
    .content22 {
      width: 17%;
    }

    .item3 {
      width: 15%;
    }
    .content3 {
      width: 21%;
    }
    .item12 {
      width: 20%;
    }
    .content13 {
      width: 30%;
    }
    .item32 {
      width: 20%;
    }
    .content32 {
      width: 21%;
    }

    .ippan-text-1 {
      width: 25%;
      padding-left: 60px;
    }
    .ippan-text-2 {
      width: 75%;
      padding-bottom: 0%;
    }
    .ippan-text-3 {
      width: 45%;
    }

    .sb-products {
      position: relative;
      padding: 5px;
      margin-left: 20px;
    }

    .sb-products::after {
      content: '';
      width: 15px;
      height: 100%;
      position: absolute;
    }

    .sb-products::after {
      border-right: solid 1px #797878;
      border-top: solid 1px #797878;
      border-bottom: solid 1px #797878;
      top: 0;
      right: 0;
    }
    .arrow-icon {
      width: 5px;
      height: auto;
      padding-bottom: 0%;
      padding-right: 12px;
    }

    .damageReasonPhoto {
      padding-left: 140px;
    }

    .sb-product-line {
      padding: 3px;
    }

  }



// table {
//     background-color: #dfe6e9;  /* 背景色指定 */
//     color: black;
// }
// th,td {
//     border: solid 1px;  /* 枠線指定 */
//     padding: 15px;      /* 余白指定 */
// }

// th {
//     background-color: #343e55;  /* 背景色指定 */
//     color: white;
// }

</style>